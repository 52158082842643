<template>
	<v-container fluid>
		<div class="d-flex flex-wrap mb-4">
			<v-btn color="primary" large @click="guardarCuenta">
				<v-icon left>mdi-content-save</v-icon>Guardar
			</v-btn>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-on="on"
						v-bind="attrs"
						large
						text
						class="mx-1"
						:loading="loading"
						@click="erroresCampos = !$refs.formulario.validate()"
						:color="
							erroresCampos == null ? '' : erroresCampos ? 'error' : 'success'
						"
					>
						<v-icon left v-if="erroresCampos === true">mdi-alert-circle-outline</v-icon>
						<v-icon left v-else-if="erroresCampos === false">mdi-check</v-icon>Consultar campos
					</v-btn>
				</template>
				<span v-if="!erroresCampos">Consultar campos faltantes o erroneos</span>
				<span v-else>Hay campos erróneos, revisa todos los campos</span>
			</v-tooltip>
		</div>
		<v-form ref="formulario">
			<v-row>
				<v-col cols="12" md="6">
					<tarjeta-datos header titulo="Datos de la cuenta" icon="account">
						<aux-input title="Nombre a mostrar">
							<v-text-field
								v-model="cuenta.nombre"
								:rules="[rules.req, rules.nombreRepetido]"
								placeholder="Nombre"
								filled
								hide-details="auto"
								dense
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Usuario">
							<v-text-field
								v-model="cuenta.usuario"
								:rules="[rules.req, rules.usuarioRepetido]"
								placeholder="Usuario"
								filled
								hide-details="auto"
								dense
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Contraseña">
							<v-text-field
								v-model="cuenta.password"
								:rules="[rules.req]"
								placeholder="Contraseña"
								filled
								hide-details="auto"
								dense
								:type="show1 ? 'text' : 'password'"
							>
								<template v-slot:append>
									<v-icon v-if="show1" @click.stop="show1 = false">mdi-eye</v-icon>
									<v-icon v-else @click.stop="show1 = true">mdi-eye-off</v-icon>
								</template>
							</v-text-field>
						</aux-input>
						<v-divider />
						<aux-input title="Email">
							<v-text-field
								v-model="cuenta.email"
								:rules="[rules.req]"
								placeholder="Email"
								filled
								hide-details="auto"
								dense
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Teléfono">
							<v-text-field
								v-model="cuenta.telefono"
								placeholder="Teléfono"
								filled
								hide-details="auto"
								dense
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Portal">
							<v-select
								v-model="cuenta.idEmpresa"
								placeholder="Portal"
								filled
								hide-details="auto"
								dense
                :items="empresas"
								item-text="nombreEmpresa"
								item-value="idEmpresa"
							/>
							<!-- <v-text-field
								v-model="cuenta.empresa"
								placeholder="Portal"
								filled
								hide-details="auto"
								dense
							/> -->
						</aux-input>
						<v-divider />
						<aux-input title="Formato">
							<v-select
								v-model="cuenta.idFormato"
								placeholder="Formato"
								filled
								hide-details="auto"
								dense
								:items="formatos"
								item-text="nombreFormato"
								item-value="idFormato"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Descripción">
							<v-textarea
								v-model="cuenta.descripcion"
								placeholder="Descripción"
								filled
								hide-details="auto"
								dense
								no-resize
							/>
						</aux-input>
					</tarjeta-datos>
				</v-col>

				<v-col cols="12" md="6">
					<tarjeta-datos header titulo="Otras opciones" icon="cog">

						<aux-input title="Instaladora">
							<v-autocomplete
								v-model="cuenta.idInstaladora"
								placeholder="Instaladora"
								item-text="nombreInstaladora"
								clearable
								:items="instaladoras"
								filled
								hide-details="auto"
								dense
								item-value="idInstaladora"
								item-disabled="disabled"
							>
							</v-autocomplete>
						</aux-input>

						<aux-input title="Depende de">
							<v-autocomplete
								v-model="cuenta.idParent"
								placeholder="Usuario"
								item-text="nombre"
								clearable
								:items="usuarios"
								filled
								hide-details="auto"
								dense
								item-value="idUsuario"
								item-disabled="disabled"
							>
								<template v-slot:selection="{ item }">
									<v-list-item-content>
										<v-list-item-title v-text="item.nombre" />
									</v-list-item-content>
								</template>
								<template v-slot:item="{ item }">
									<v-list-item-content>
										<v-list-item-title v-text="item.nombre" />
									</v-list-item-content>
									<v-chip x-small color="secondary" v-text="item.administra_a.length" />
								</template>
							</v-autocomplete>
						</aux-input>

						<aux-input title="Responsable">
							<v-autocomplete
								v-model="cuenta.idResponsable"
								placeholder="Usuario"
								item-text="nombre"
								clearable
								:items="usuarios"
								filled
								hide-details="auto"
								dense
								item-value="idUsuario"
								item-disabled="disabled"
							>
							</v-autocomplete>
						</aux-input>

						<v-divider />
						<!-- <aux-input title="Permisos"> -->
							<v-list>
							<v-list-item-group v-model="cuenta.permisos_arr" multiple>
								<v-list-item
									dense
									:value="Number(permiso.valor)"
									:key="permiso.valor"
									v-for="permiso in permisos"
								>
									<template v-slot:default="{ active }">
										<v-list-item-action>
											<v-checkbox :input-value="active"></v-checkbox>
										</v-list-item-action>

										<v-list-item-content>
											<!-- <v-list-item-title>{{ permiso.desc }}</v-list-item-title> -->
											<v-list-item-subtitle>{{ permiso.permiso }}</v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</v-list-item>
							</v-list-item-group>
						</v-list>
							<!-- <v-autocomplete
								v-model="cuenta.permisos"
								placeholder="Permisos"
								item-text="permiso"
								item-key="value"
								clearable
								:items="permisos"
								filled
								hide-details="auto"
								dense
								item-value="value"
								multiple
								small-chips
							></v-autocomplete> -->
						<!-- </aux-input> -->
					</tarjeta-datos>
					<v-row class="mt-2">
						<v-col v-if="parent && Object.keys(parent).length > 0" cols="12" md="6">
							<tarjeta-datos titulo="Administrado por" icon="account-tie">
								<v-list>
									<v-list-item-group>
										<v-list-item exact :to="`/colaboradores/${parent.idUsuario}`">
											<v-list-item-title>{{ parent.nombre }}</v-list-item-title>
											<v-list-item-subtitle>{{ parent.email }}</v-list-item-subtitle>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</tarjeta-datos>
						</v-col>

						<v-col v-if="cuenta.administra_a && cuenta.administra_a.length > 0" cols="12" md="6">
							<tarjeta-datos titulo="Administra a" icon="human-child">
								<v-list>
									<v-list-item-group>
										<v-list-item exact v-for="child in cuenta.administra_a" :key="child.idUsuario" :to="`/colaboradores/${child.idUsuario}`">
											<v-list-item-title>{{ child.nombre }}</v-list-item-title>
											<v-list-item-subtitle>{{ child.email }}</v-list-item-subtitle>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</tarjeta-datos>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>

<script>
import { req } from '@/utils/validations.js'

export default {
	components: {
		TarjetaDatos: () => import('@/components/TarjetaDatos.vue'),
		AuxInput: () => import('@/components/AuxInput.vue'),
	},
  props: {
    idUsuario: {
      type: String | Number,
      required: false
    }
  },
	data() {
		return {
			usuarios: [],
			children: [],
			parent: Object.create(null),
			cuenta: { permisos_arr: [] },
			permisos: [],
      empresas: [],
      formatos: [],
			instaladoras: [],
			rules: {
				req,
				nombreRepetido: (v) => !this.usuarios.filter(u => u.idUsuario != this.idUsuario).map(u => u.nombre).includes(v) || 'Este nombre ya existe',
				usuarioRepetido: (v) => !this.usuarios.filter(u => u.idUsuario != this.idUsuario).map(u => u.usuario).includes(v) || 'Este nombre de usuario ya existe',
			},
			loading: false,
			erroresCampos: null,

			show1: false,
			show2: false,
		}
	},
	async mounted() {
		if (this.idUsuario) {
			const { data: cuenta } = await axios({
				url: `${process.env.VUE_APP_API_URL}/colaboradores/${this.idUsuario}`,
				method: 'GET',
			})
			this.cuenta = cuenta
		}
    const { data: permisos } = await axios({
      url: `${process.env.VUE_APP_API_URL}/auth/permisos`,
      method: 'GET',
      params: { as_list: true }
    })
    this.permisos = permisos

		const { data: usuarios } = await axios(`${process.env.VUE_APP_API_URL}/colaboradores`)
		this.usuarios = usuarios.filter( u => this.idUsuario == null || u.idUsuario != this.idUsuario )

		this.parent = this.usuarios.find(u => u.idUsuario == this.cuenta.idParent) || Object.create(null)
		// this.children = this.usuarios.filter(u => u.idParent == this.cuenta.idUsuario)

		// this.permisos = Object.entries(this.$store.getters.getPermisos).filter(([key,]) => this.$root.acceso(key)).map(([key, value]) => ({ permiso: key, value }));

		if (this.idUsuario)
			this.cuenta.permisos_arr = this.permisos.filter(({ permiso }) => this.cuenta.permisos & this.$store.getters.getPermisos[permiso]).map(x => Number(x.valor))

    this.getEmpresas()
    this.getFormatos()
		this.getInstaladoras()
	},
	methods: {
		guardarCuenta() {
			if (!this.$refs.formulario.validate()) return;
			this.cuenta.permisos ??= 0;
			axios({
				method: 'POST',
				url: `${process.env.VUE_APP_API_URL}/colaboradores${ this.idUsuario ? `/${this.idUsuario}` : '' }`,
				data: { ...this.cuenta, permisos: this.cuenta.permisos_arr.reduce((a, t) => a + t, 0) }
			}).then(res => {
				this.$router.push({ name: 'Colaboradores' });
			}).catch(err => {
				console.error(err)
        this.$root.$emit('snack', 'Ha ocurrido un error al crear la cuenta', 'error')
			})
		},
    async getEmpresas() {
      const { data } = await axios(`${process.env.VUE_APP_API_URL}/empresa`)
      this.empresas = data
    },
    async getFormatos() {
      const { data } = await axios(`${process.env.VUE_APP_API_URL}/formato`)
      this.formatos = data
    },
    async getInstaladoras() {
      const { data } = await axios(`${process.env.VUE_APP_API_URL}/instaladoras`)
      this.instaladoras = data
    }
	}
};
</script>